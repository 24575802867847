import React from 'react';
import Navbar from '../navbar/Navbar';
import CartPage from './sous page/CartPage';
import Footer from '../footer/Footer';

const Commande = () => {
    return (
        <div>
            <Navbar/>
            <CartPage/>
            <Footer/>
        </div>
    );
};

export default Commande;