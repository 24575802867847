import React from 'react';

const EspaceNote = ({ notes }) => {
    return (
        <div>
            <h3 className="text-xl font-semibold mb-4">Notes</h3>
            <table className="min-w-full divide-y divide-gray-200">
                <thead className='bg-green-200'>
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Client</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Produit</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Note</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Date</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {notes.length > 0 ? (
                        notes.map(note => (
                            <tr key={note.id} className="hover:bg-gray-100">
                                <td className="px-6 py-4 whitespace-nowrap">{note.client_username}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{note.produit_titre}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{note.note_value}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{new Date(note.date_avis).toLocaleDateString()}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4" className="px-6 py-4 text-center text-gray-600">Aucune note disponible</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default EspaceNote;
