// CartContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const CartContext = createContext();

export const useCart = () => {
    return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState([]);
    const [userId, setUserId] = useState(localStorage.getItem('user_id'));

    useEffect(() => {
        // Charger le panier du localStorage si l'utilisateur est connecté
        if (userId) {
            const savedCart = localStorage.getItem(`cart_${userId}`);
            if (savedCart) {
                setCart(JSON.parse(savedCart));
            }
        }
    }, [userId]);

    const addToCart = (product, quantity) => {
        setCart(prevCart => {
            const existingProductIndex = prevCart.findIndex(item => item.id === product.id);
            const updatedCart = existingProductIndex >= 0
                ? prevCart.map((item, index) =>
                    index === existingProductIndex
                        ? { ...item, quantity: item.quantity + quantity }
                        : item
                )
                : [...prevCart, { ...product, quantity }];
                
            if (userId) {
                localStorage.setItem(`cart_${userId}`, JSON.stringify(updatedCart));
            }

            return updatedCart;
        });
    };

    const removeFromCart = (productId) => {
        setCart(prevCart => {
            const updatedCart = prevCart.filter(item => item.id !== productId);
            if (userId) {
                localStorage.setItem(`cart_${userId}`, JSON.stringify(updatedCart));
            }
            return updatedCart;
        });
    };

    const clearCart = () => {
        setCart([]);
        if (userId) {
            localStorage.removeItem(`cart_${userId}`);
        }
    };

    return (
        <CartContext.Provider value={{ cart, addToCart, removeFromCart, clearCart }}>
            {children}
        </CartContext.Provider>
    );
};
