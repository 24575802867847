import React from 'react';
import Navbar from '../navbar/Navbar';
import CategoriePage from './sous page/CategoriePage';
import Footer from '../footer/Footer';

const Categorie = () => {
    return (
        <div>
            <Navbar/>
            <CategoriePage/>
            <Footer/>
        </div>
    );
};

export default Categorie;