import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ConfigHost from '../../src/ConfigHost';

const ChatBox = ({ productId, userId }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const response = await axios.get(`${ConfigHost.API_URL}/messages/?produit=${productId}`);
                setMessages(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des messages', error);
            }
        };

        fetchMessages();
    }, [productId]);

    const handleSendMessage = async () => {
        if (newMessage.trim() !== '' && userId) {
            try {
                const response = await axios.post(`${ConfigHost.API_URL}/messages/`, {
                    client: userId,
                    produit: productId,
                    texte_message: newMessage
                });
                setMessages([...messages, response.data]);
                setNewMessage('');
            } catch (error) {
                console.error('Erreur lors de l\'envoi du message', error);
            }
        }
    };

    return (
        <div className="w-1/2 border border-gray-300 p-4 rounded-lg">
            <h2 className="text-2xl mb-4">Discussion</h2>
            <div className="h-64 overflow-y-auto mb-4 p-4 bg-gray-100 rounded-lg">
                {messages.length > 0 ? (
                    messages.map((message, index) => (
                        <div key={index} className="mb-2">
                            <strong>{message.client_username || 'Anonyme'}:</strong>
                            <p>{message.texte_message}</p>
                        </div>
                    ))
                ) : (
                    <p className="text-gray-600">Aucun message pour l'instant</p>
                )}
            </div>
            <div className="flex items-center">
                <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    className="flex-grow border p-2 rounded-lg"
                    placeholder="Écrivez un message..."
                />
                <button
                    onClick={handleSendMessage}
                    className="bg-green-500 text-white p-2 ml-2 rounded-lg hover:bg-green-600"
                >
                    Envoyer
                </button>
            </div>
        </div>
    );
};

export default ChatBox;
