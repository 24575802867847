import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ConfigHost from '../../../../ConfigHost';

const LesAchats = () => {
    const [userId] = useState(localStorage.getItem('user_id'));
    const [commandes, setCommandes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCommandes = async () => {
            try {
                const response = await axios.get(`${ConfigHost.API_URL}/commandes/?fournisseur_id=${userId}`);
                setCommandes(response.data);
            } catch (error) {
                setError('Erreur lors de la récupération des commandes');
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchCommandes();
    }, [userId]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="p-6">
            <h2 className='text-xl mb-7'>Accueil / <span className='text-gray-500'>Liste des Ventes</span></h2>
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Mes produits vendus</h3>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white divide-y divide-gray-200 shadow-md rounded-lg">
                    <thead className="bg-green-600 text-white">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Client</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Produit</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Quantité</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Prix</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Total</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Date</th>
                            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Heure</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {commandes.map((commande) => (
                            <tr key={commande.id} className="hover:bg-gray-100">
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{commande.client_username}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{commande.produit_titre}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{commande.quantite}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{commande.prix} FCFA</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{commande.total} FCFA</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(commande.created_at).toLocaleDateString()}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(commande.created_at).toLocaleTimeString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default LesAchats;
