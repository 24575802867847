import React from 'react';
import Navbar from '../navbar/Navbar';
import ProduitDetail from './sous page/ProduitDetail';
import Footer from '../footer/Footer';

const Detail = () => {
    return (
        <div>
            <Navbar/>
            <ProduitDetail/>
            <Footer/>
        </div>
    );
};

export default Detail;