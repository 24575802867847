import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/consomlocal.png';

const Footer = () => {
    return (
        <div>
            <footer id='contact' className="footer bg-base-200 text-base-content p-10">
                <aside>
                <div className="flex-1 flex justify-center lg:justify-start">
                    <Link
                        to="/"
                        className='btn btn-ghost text-2xl'>
                        <span className='text-red-500'>Consom<span className='text-green-500'>Local</span ></span>
                    </Link>
                </div>
                    <img
                        src={Logo}
                        className="md:max-w-sm rounded-lg h-28 shadow-2xl" />
                    <p className='text-md'>
                    Découvrez une sélection unique de 
                    <br />
                    produits endogènes du Burkina Faso.
                    </p>
                </aside>
                <nav>
                    <h6 className="footer-title">Services</h6>
                    <a className="link link-hover">Commerce</a>
                    <a className="link link-hover">Artisanat</a>
                    <a className="link link-hover">Marketing</a>
                    <a className="link link-hover">Vente</a>
                </nav>
                <nav>
                    <h6 className="footer-title">Entreprise</h6>
                    <a className="link link-hover">A propos</a>
                    <a className="link link-hover">Contact</a>
                    <a className="link link-hover">Produit</a>
                    <a className="link link-hover">Art</a>
                </nav>
                <nav>
                    <h6 className="footer-title">Legal</h6>
                    <a className="link link-hover">Terme et Condition</a>
                    <a className="link link-hover">Politique privee</a>
                    <a className="link link-hover">Cookie</a>
                </nav>
                <div className="footer footer-center bg-base-300 text-base-content p-4">
                    <aside>
                        <p>Copyright © {new Date().getFullYear()} - Tous droits Reserves vente</p>
                    </aside>
                </div>
            </footer>
        </div>
    );
};

export default Footer;