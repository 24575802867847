import React from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import FavorisPage from './sous page/FavorisPage';

const Favoris = () => {
    return (
        <div>
            <Navbar/>
            <FavorisPage/>
            <Footer/>
        </div>
    );
};

export default Favoris;