// App.js
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/pages/Home';
import Inscription from './components/pages/Inscription';
import Detail from './components/pages/Detail';
import { CartProvider } from './components/CartContext';
import Dashboard from './components/pages/sous page/Dashboard';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Categorie from './components/pages/Categorie';
import Commande from './components/pages/Commande';
import Profil from './components/pages/Profil';
import Contact from './components/pages/Contact';
import Propos from './components/pages/Propos';
import Favoris from './components/pages/Favoris';
import ProtectedRoute from './components/ProtectedRoute';

const App = () => {
  return (
      <CartProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/inscription" element={<Inscription />} />
            <Route path="/produit/:id" element={<Detail />} />
            <Route path="/dashboard/*" element={<ProtectedRoute element={<Dashboard />} />} />
            <Route path="/categorie/:id" element={<Categorie/>} />
            <Route path="/commande" element={<Commande/>} />
            <Route path="/profil" element={<Profil/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/apropos" element={<Propos/>} />
            <Route path="/favoris" element={<Favoris/>} />
          </Routes>
        </BrowserRouter>
      </CartProvider>
  );
};

export default App;
