import React from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import ContactForm from './sous page/ContactForm';

const Contact = () => {
    return (
        <div>
            <Navbar/>
            <ContactForm/>
            <Footer/>
        </div>
    );
};

export default Contact;