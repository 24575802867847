import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import axios from 'axios';
import ConfigHost from '../../src/ConfigHost';
import Orange from '../assets/Orange.png';

const OrangeMoney = ({ isOpen, onClose, totalAmount, cart }) => {
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otpCode, setOtpCode] = useState('');

    const handlePayment = async () => {
        const user_id = localStorage.getItem('user_id');
        const user_email = localStorage.getItem('user_email');
        const username = localStorage.getItem('username');

        // Créer les commandes et paiements
        try {

            for (const item of cart) {
                // 1. Envoi de la requête pour créer la commande
                const orderResponse = await axios.post(`${ConfigHost.API_URL}/commandes/`, {
                    client: user_id,
                    produit: item.id,
                    quantite: item.quantity,
                });

                console.log('Réponse de la commande:', orderResponse.data);

                // Récupération de l'ID de la commande créée
                const orderId = orderResponse.data.id;

                // 2. Envoi de la requête pour créer le paiement
                const paymentResponse = await axios.post(`${ConfigHost.API_URL}/paiement/`, {
                    commande: orderId,
                    montant: totalAmount,
                    moyen_paiement: 'Orange Money',
                });

                // Envoi de l'email de confirmation
                const emailResponse = await axios.post(`${ConfigHost.API_URL}/send-confirmation-email/`, {
                    email: user_email,
                    total_amount: totalAmount,
                    username: username,
                });

                // Vérifier la réponse du paiement
                if (paymentResponse.status !== 201) {
                    // Gérer les erreurs de paiement
                    console.error('Erreur lors du paiement: Status -', paymentResponse.status, 'Données -', paymentResponse.data);
                    throw new Error('Erreur lors du paiement');
                }
            }

            // Si toutes les commandes et paiements sont réussis
            setShowSuccessMessage(true);
            setTimeout(() => onClose(), 2000);

        } catch (error) {
            console.error('Erreur:', error.message || error);
        }
    };

    const handleCloseSuccess = () => {
        setShowSuccessMessage(false);
        onClose();
    };

    return ReactDOM.createPortal(
        <>
            <AnimatePresence>
                {isOpen && !showSuccessMessage && (
                    <motion.div
                        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <motion.div
                            className="bg-white md:w-1/4 w-10/12 h-4/5 md:h-3/4 p-3 rounded-lg shadow-lg relative"
                            initial={{ scale: 0.8 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0.8 }}
                        >
                            <button
                                className="absolute top-2 right-2 text-gray-500"
                                onClick={onClose}
                                aria-label="Close"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                            <div className="w-full p-1 flex justify-center">
                                <img src={Orange} alt="Orange Money" className="w-1/3" />
                                <h3 className="text-lg font-bold w-1/4">Orange Money</h3>
                            </div>
                            <h2 className="text-lg font-bold my-2 text-center">Confirmation de paiement</h2>
                            <div className="mb-2">
                                <p className="text-orange-300 text-md font-semibold">Montant de paiement: {totalAmount.toFixed(2)} FCFA</p>
                                <p className="text-sm text-red-400 text-center my-4">Composer ce code pour générer un code OTP avec le montant du paiement : *144*4*6*Montant#</p>
                            </div>
                            <div className="space-y-4 mb-1">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Numéro de téléphone</label>
                                    <input
                                        type="text"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        placeholder="Entrez votre numéro de téléphone"
                                        className="mt-1 block w-full border-gray-300 bg-orange-100 rounded-lg shadow-sm px-3 py-1"
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Code OTP</label>
                                    <input
                                        type="text"
                                        value={otpCode}
                                        onChange={(e) => setOtpCode(e.target.value)}
                                        placeholder="Entrez le code OTP"
                                        className="mt-1 block w-full border-gray-300 bg-orange-100 rounded-lg shadow-sm px-3 py-1"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="absolute bottom-4 left-4 right-4">
                                <button
                                    onClick={handlePayment}
                                    className="bg-orange-500 w-full p-3 text-white rounded-lg"
                                >
                                    Payer
                                </button>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {showSuccessMessage && (
                    <motion.div
                        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <motion.div
                            className="bg-white w-10/12 md:w-1/4 p-4 rounded-lg shadow-lg"
                            initial={{ scale: 0.8 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0.8 }}
                        >
                            <h2 className="text-xl font-bold text-green-600">Succès</h2>
                            <p className="mt-2 text-center">Votre paiement a été validé avec succès. Un email sera envoyé à votre boîte email afin de garantir la traçabilité de votre paiement.</p>
                            <div className="flex justify-center mt-4">
                                <button
                                    onClick={handleCloseSuccess}
                                    className="bg-green-500 text-white px-4 py-2 rounded"
                                >
                                    OK
                                </button>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>,
        document.body
    );
};

export default OrangeMoney;
