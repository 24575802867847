import React, { useState } from 'react';
import { useNavigate, Routes, Route, Link, useLocation } from 'react-router-dom';
import TableauDeBord from './fournisseur/TableauDeBord';
import GestionProduits from './fournisseur/GestionProduits';
import LesAchats from './fournisseur/LesAchats';
import Solde from './fournisseur/Solde';
import Evaluation from './fournisseur/Evaluation';
import Message from './fournisseur/Message';

const Dashboard = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);
    const username = localStorage.getItem('username');
    const totalItems = 5;

    const handleLogout = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('username');
        localStorage.removeItem('user_type');
        localStorage.removeItem('user_id');
        navigate('/');
    };

    return (
        <div className="flex flex-col h-screen overflow-hidden"> {/* Ajouter overflow-hidden */}
            <header className="h-16 bg-gray-50 border border-gray-200 text-white flex items-center justify-between p-4 shadow">
                <h2 className='text-gray-600 text-2xl font-serif ml-3'>Espace Fournisseur</h2>
                <div className="flex items-center flex-grow justify-center w-max space-x-4">
                    <div className='bg-green-100 p-1 rounded-full'>
                        <input
                            type="text"
                            placeholder="Rechercher..."
                            className="p-0 rounded-md text-gray-800 bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-500"
                        />
                        <button type="submit" className="text-2xl bg-green-100 text-gray-500 lg:h-15"><i className="bi bi-search"></i></button>
                    </div>
                </div>
                <div className="flex items-center space-x-4">
                    <div className="relative">
                        <div className="indicator">
                            <i className="text-black text-2xl bi bi-bell"></i>
                            <span className="absolute -top-1 -right-1 text-xs font-semibold text-white bg-red-600 rounded-full px-1">{totalItems}</span>
                        </div>
                    </div>
                    <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
                        <div className="w-8 rounded-full">
                            <img
                                alt="User Avatar"
                                src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.webp" />
                        </div>
                    </div>
                </div>
            </header>

            <div className="flex flex-1 overflow-hidden"> {/* Ajouter overflow-hidden */}
                <aside className="w-1/6 h-full bg-gray-50 text-gray-800 flex flex-col border border-gray-200">
                    <h2 className="text-2xl text-center font-bold p-4 my-6">
                        <span className='text-red-500'>Consom<span className='text-green-500'>Local</span ></span>
                    </h2>
                    <nav className="flex flex-col p-2 gap-4 mt-2">
                        <Link
                            to="tableau-de-bord"
                            className={`text-sm p-2 rounded-lg flex items-center space-x-2 border border-transparent hover:border-green-600 ${activeLink === "/tableau-de-bord" ? "bg-green-200" : ""}`}
                            onClick={() => setActiveLink("/tableau-de-bord")}
                        >
                            <i className="text-xl bi bi-house"></i> <span>Tableau de bord</span>
                        </Link>
                        <Link
                            to="gestion-produits"
                            className={`text-sm p-2 rounded-lg flex items-center space-x-2 border border-transparent hover:border-green-600 ${activeLink === "/gestion-produits" ? "bg-green-200" : ""}`}
                            onClick={() => setActiveLink("/gestion-produits")}
                        >
                            <i className="text-xl bi bi-cart"></i> <span>Gestion de Produits</span>
                        </Link>
                        <Link
                            to="les-achats"
                            className={`text-sm p-2 rounded-lg flex items-center space-x-2 border border-transparent hover:border-green-600 ${activeLink === "/les-achats" ? "bg-green-200" : ""}`}
                            onClick={() => setActiveLink("/les-achats")}
                        >
                            <i className="text-xl bi bi-arrow-repeat"></i> <span>Liste des Ventes</span>
                        </Link>
                        <Link
                            to="solde"
                            className={`text-sm p-2 rounded-lg flex items-center space-x-2 border border-transparent hover:border-green-600 ${activeLink === "/solde" ? "bg-green-200" : ""}`}
                            onClick={() => setActiveLink("/solde")}
                        >
                            <i className="text-xl bi bi-wallet2"></i> <span>Accéder au solde</span>
                        </Link>
                        <Link
                            to="evaluations"
                            className={`text-sm p-2 rounded-lg flex items-center space-x-2 border border-transparent hover:border-green-600 ${activeLink === "/evaluations" ? "bg-green-200" : ""}`}
                            onClick={() => setActiveLink("/evaluations")}
                        >
                            <i className="text-xl bi bi-chat-text"></i> <span>Consulter les évaluations</span>
                        </Link>
                        <Link
                            to="discuter-client"
                            className={`text-sm p-2 rounded-lg flex items-center space-x-2 border border-transparent hover:border-green-600 ${activeLink === "/discuter-client" ? "bg-green-200" : ""}`}
                            onClick={() => setActiveLink("/discuter-client")}
                        >
                            <i className="text-xl bi bi-send-check"></i> <span>Discuter avec un client</span>
                        </Link>
                        <button
                            onClick={handleLogout}
                            className="w-full py-2 px-4 bg-red-600 flex items-center text-white font-semibold rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                            <i className="text-xl bi bi-box-arrow-in-left mr-5"></i>
                            Se déconnecter
                        </button>
                    </nav>
                </aside>
                <main className="w-5/6 p-8 overflow-y-auto"> {/* Ajouter overflow-y-auto */}
                    <Routes>
                        <Route path="tableau-de-bord" element={<TableauDeBord />} />
                        <Route path="gestion-produits" element={<GestionProduits />} />
                        <Route path="les-achats" element={<LesAchats />} />
                        <Route path="solde" element={<Solde />} />
                        <Route path="evaluations" element={<Evaluation />} />
                        <Route path="discuter-client" element={<Message/>} />
                    </Routes>
                </main>
            </div>

        </div>
    );
};

export default Dashboard;
