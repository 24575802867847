import React from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import Apropos from './sous page/Apropos';

const Propos = () => {
    return (
        <div>
            <Navbar/>
            <Apropos/>
            <Footer/>
        </div>
    );
};

export default Propos;