import React, { useState } from 'react';
import axios from 'axios';

const Message = () => {
  const [receiver, setReceiver] = useState('');
  const [content, setContent] = useState('');
  const [status, setStatus] = useState('');

  const handleSendMessage = async () => {
    try {
      const response = await axios.post('/send_message/', {
        receiver,
        content
      });
      setStatus('Message envoyé avec succès');
    } catch (error) {
      setStatus('Erreur lors de l\'envoi du message');
    }
  };

  return (
    <div>
      <h2>Envoyer un message</h2>
      <input
        type="text"
        placeholder="Nom d'utilisateur du destinataire"
        value={receiver}
        onChange={(e) => setReceiver(e.target.value)}
      />
      <textarea
        placeholder="Contenu du message"
        value={content}
        onChange={(e) => setContent(e.target.value)}
      />
      <button onClick={handleSendMessage}>Envoyer</button>
      {status && <p>{status}</p>}
    </div>
  );
};

export default Message;
