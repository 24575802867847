import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ConfigHost from '../../../../ConfigHost';

const AjoutProduit = ({ formData, setFormData, fetchProduits, editMode, produitId, resetForm }) => {
    const [photoPreview, setPhotoPreview] = useState(null);
    const [categories, setCategories] = useState([]);
    const user_id = localStorage.getItem('user_id');

    useEffect(() => {
        fetchCategories();
        if (editMode && formData.photo) {
            setPhotoPreview(formData.photo); // Mettez à jour l'aperçu si un produit est en mode édition
        }
    }, [editMode, formData.photo]);

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${ConfigHost.API_URL}/categories/`);
            setCategories(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des catégories:', error);
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            photo: file,
        });
        setPhotoPreview(URL.createObjectURL(file));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append('titre', formData.titre);
        data.append('description', formData.description);
        data.append('prix', formData.prix);
        data.append('categorie', formData.categorie);
        data.append('promo', formData.promo);
        if (formData.photo) {
            data.append('photo', formData.photo);
        }
        data.append('fournisseur', user_id);

        try {
            if (editMode && produitId) {
                // Mettre à jour le produit existant
                await axios.put(`${ConfigHost.API_URL}/produits/${produitId}/`, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                });
            } else {
                // Ajouter un nouveau produit
                await axios.post(`${ConfigHost.API_URL}/produits/`, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                });
            }
            fetchProduits();
            resetForm(); // Réinitialiser le formulaire
        } catch (error) {
            console.error('Erreur lors de l\'ajout du produit:', error.response.data);
        }
    };

    return (
        <div className="mb-6 p-4 bg-white shadow-md rounded-lg">
            <h3 className="text-xl font-semibold mb-4">
                {editMode ? 'Modifier le produit' : 'Ajouter un produit'}
            </h3>
            <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div className="sm:col-span-1">
                    <label htmlFor="titre" className="block text-sm font-medium text-gray-700">Titre</label>
                    <input
                        type="text"
                        id="titre"
                        name="titre"
                        value={formData.titre}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-400 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                    />
                </div>

                <div className="sm:col-span-1">
                    <label htmlFor="categorie" className="block text-sm font-medium text-gray-700">Catégorie</label>
                    <select
                        id="categorie"
                        name="categorie"
                        value={formData.categorie}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                    >
                        <option value="">Sélectionnez une catégorie</option>
                        {categories.map(cat => (
                            <option key={cat.id} value={cat.id}>{cat.contenu}</option>
                        ))}
                    </select>
                </div>

                <div className="sm:col-span-1">
                    <label htmlFor="prix" className="block text-sm font-medium text-gray-700">Prix</label>
                    <input
                        type="number"
                        id="prix"
                        name="prix"
                        value={formData.prix}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-400 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                    />
                </div>

                <div className="sm:col-span-1">
                    <label htmlFor="promo" className="block text-sm font-medium text-gray-700">Promo</label>
                    <input
                        type="text"
                        id="promo"
                        name="promo"
                        value={formData.promo}
                        onChange={handleChange}
                        className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-400 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                    />
                </div>

                <div className="sm:col-span-2">
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                    <textarea
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        required
                        rows="3"
                        className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-400 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                    />
                </div>

                <div className="sm:col-span-2">
                    <label htmlFor="photo" className="block text-sm font-medium text-gray-700">Photo</label>
                    <input
                        type="file"
                        id="photo"
                        name="photo"
                        onChange={handlePhotoChange}
                        className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-400 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                    />
                    {photoPreview && (
                        <div className="mt-2">
                            <img src={photoPreview} alt="Aperçu de la photo" className="max-h-40" />
                        </div>
                    )}
                </div>

                <div className="sm:col-span-2 text-right">
                    <button
                        type="submit"
                        className="mt-4 inline-flex items-center px-4 py-2 bg-green-600 border border-transparent rounded-md font-semibold text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                    >
                        {editMode ? 'Mettre à jour le produit' : 'Ajouter le produit'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AjoutProduit;
