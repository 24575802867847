import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ConfigHost from '../../../ConfigHost';

const FavorisPage = () => {
  const [produits, setProduits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const userId = localStorage.getItem('user_id'); // Récupérer l'ID de l'utilisateur depuis le localStorage

  useEffect(() => {
    const fetchProduits = async () => {
      try {
        const produitResponse = await axios.get(`${ConfigHost.API_URL}/commandes/?client=${userId}`);
        
        // Récupérer les IDs des produits à partir de la réponse
        const productIds = produitResponse.data.map(command => command.produit); // Assurez-vous que 'produit' est le champ qui contient l'ID

        // Effectuer des requêtes pour récupérer les détails de chaque produit
        const produitsDetails = await Promise.all(
          productIds.map(id => axios.get(`${ConfigHost.API_URL}/produits/${id}/`))
        );

        // Extraire les données des produits
        const produitsData = produitsDetails.map(response => response.data);
        setProduits(produitsData); // Mettre à jour l'état avec les détails des produits
      } catch (err) {
        setError('Erreur lors de la récupération des produits.');
      } finally {
        setLoading(false);
      }
    };

    if (userId) { // Vérifier si userId existe avant de faire la requête
      fetchProduits();
    } else {
      setError('Utilisateur non connecté.');
      setLoading(false);
    }
  }, [userId]);

  if (loading) {
    return <div className="text-center text-lg">Chargement...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-6 text-center">Mes Produits Favoris</h1>
      {produits.length > 0 ? (
        <div className="flex flex-wrap justify-center space-x-4">
          {produits.map((produit) => (
            <div key={produit.id_prod} className="w-64 p-4 border border-gray-200 rounded-lg shadow hover:shadow-lg transition mb-4">
              <img 
                src={produit.photo} // Assurez-vous que 'photo' est le champ contenant l'URL de l'image
                alt={produit.titre} 
                className="w-full h-40 object-cover rounded-md mb-2" 
              />
              <h2 className="text-xl font-semibold">{produit.titre}</h2>
              <p className="text-gray-600">{produit.description}</p>
              <p className="text-green-600 font-bold">{produit.prix} FCFA</p>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-500">Aucun produit favori trouvé.</p>
      )}
    </div>
  );
};

export default FavorisPage;
