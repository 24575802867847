import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ConfigHost from '../../../../ConfigHost';
import { Link } from 'react-router-dom';
import Secure from '../../../../assets/Secure.png';
import Formulaire from '../inscription/Formulaire';
import '../../../../css/ListeProduit.css';

const Connexion = ({ isOpen, onClose }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isShowPopup, setShowPopup] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showChatBox, setShowChatBox] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();

        axios.post(`${ConfigHost.API_URL}/login/`, {
            email: email,
            password: password,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.data.access) {
                    setError('');
                    localStorage.setItem('access_token', response.data.access);
                    localStorage.setItem('refresh_token', response.data.refresh);
                    localStorage.setItem('username', response.data.username);
                    localStorage.setItem('user_type', response.data.user_type);
                    localStorage.setItem('user_id', response.data.user_id);
                    localStorage.setItem('user_email', response.data.user_email);

                    if (response.data.user_type === 'fournisseur') {
                        navigate('/dashboard/tableau-de-bord');
                    } else {
                        navigate('/');
                    }
                    onClose();  // Fermer le popup après la connexion réussie
                } else {
                    setError('Email ou mot de passe incorrect.');
                }
            })
            .catch(error => {
                setError(`Une erreur est survenue: ${error.message}`);
                console.error('Login error:', error);
            });
    };

    // Fermer le modal quand on clique à l'extérieur
    const handleOutsideClick = (e) => {
        if (e.target.classList.contains('modal-overlay')) {
            onClose();
        }
    };
    const openPopup = () => {
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={handleOutsideClick}>
            <div className="w-11/12 h-3/4 md:w-4/6 md:h-4/5 bg-white rounded-xl shadow-md relative md:flex">
                <div className="md:w-1/2 flex-col bg-green-100 rounded-l-xl rounded-br-3xl rounded-tr-3xl flex items-center">
                    <div className="hidden w-11/12 h-2/4 px-3 md:flex flex-col justify-around py-5 background-image-custom">
                        <h2 className="text-3xl text-center font-bold">Vendez sur <span className='text-red-500'>Consom<span className='text-green-500'>Local</span ></span></h2>
                        <ul className='text-md px-4'>
                            <li className='py-3'><i className="text-xl bi bi-check"></i> Des produits locaux et authentiques</li>
                            <li className='py-3'><i className="text-xl bi bi-check"></i> Valorisation de la culture et du patrimoine</li>
                            <li className='py-3'><i className="text-xl bi bi-check"></i> Exclusivité et rareté des produits</li>
                        </ul>
                    </div>
                    <div className="hidden rounded-lg w-11/12 h-1/2 md:flex justify-center items-center">
                        <img
                            src={Secure}
                            className="w-10/12 h-5/6" />
                    </div>
                </div>
                <div className="h-full md:w-1/2 flex justify-around items-center flex-col pt-14">
                    <div>
                        <h1 className="text-2xl font-bold mb-6 text-center">Connectez-vous a votre compte</h1>
                    </div>
                    <div className='w-10/12'>
                        {error && <p className="text-red-500 mb-4">{error}</p>}
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <div className="mb-6">
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">Mot de passe</label>
                                <input
                                    type="password"
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full py-2 px-4 bg-green-600 text-white font-semibold rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Connexion
                            </button>
                        </form>
                    </div>
                    <div className="w-10/12">
                        <ul className='flex justify-between'>
                            <li>
                                <Link to="/whatsapp" className='text-sm md:text-md text-blue-500 hover:text-green-600'>
                                    Mot de passe oublié ?
                                </Link>
                            </li>
                            <li>
                                <button onClick={openPopup} className='p-2 text-sm text-white rounded-lg md:p-2 md:text-md bg-blue-500 hover:bg-blue-600'>
                                    Creer un compte
                                </button>
                            </li>
                            <Formulaire isOpen={isShowPopup} onClose={closePopup} />
                        </ul>
                    </div>
                    <div className='w-9/12 condition text-center'>
                        <p className='text-xs'>Et poursuivant, vous acceptez nos Conditions d'utilisation et vous confirmez que vous avez lu notre Politique de confidentialité et d'utilisation des cookies. </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Connexion;
