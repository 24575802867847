import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import photo from '../../../assets/photo.jpeg';
import image from '../../../assets/image.png';
import ConfigHost from '../../../ConfigHost';
import '../../../../src/css/ListeProduit.css';
import yennega from '../../../assets/yennega.png';
import sac from '../../../assets/sac.png';

const Layout = () => {
    const [produits, setProduits] = useState([]);
    const [categories, setCategories] = useState([]);
    const [filtrage, setFiltrage] = useState([]);
    const [filtrageMode, setFiltrageMode] = useState([]);
    const [randomProduits, setRandomProduits] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Récupérer les produits initiaux et lancer un intervalle pour mise à jour aléatoire
        const fetchProducts = () => {
            axios.get(`${ConfigHost.API_URL}/produits/`)
                .then(response => {
                    setProduits(response.data);

                    // Sélectionner 5 produits aléatoires
                    const shuffledProduits = response.data.sort(() => 0.5 - Math.random());
                    setRandomProduits(shuffledProduits.slice(0, 5)); // Prendre 5 produits
                })
                .catch(error => {
                    console.error('La récupération des produits a rencontré une erreur !', error);
                });
        };

        fetchProducts(); // Appeler une première fois la récupération des produits

        // Mettre à jour les produits toutes les 5 secondes
        const intervalId = setInterval(() => {
            fetchProducts();
        }, 20000);

        // Nettoyer l'intervalle lorsque le composant est démonté
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        // Récupérer les catégories
        axios.get(`${ConfigHost.API_URL}/categories/`)
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error('La récupération des catégories a rencontré une erreur !', error);
            });

        // Récupérer les produits de la catégorie Artisanat
        axios.get(`${ConfigHost.API_URL}/produits/?categorie=5`)
            .then(response => {
                setFiltrage(response.data);
            })
            .catch(error => {
                console.error('La récupération des produits filtrés Artisanat a rencontré une erreur !', error);
            });

        // Récupérer les produits de la catégorie Mode
        axios.get(`${ConfigHost.API_URL}/produits/?categorie=3`)
            .then(response => {
                setFiltrageMode(response.data);
            })
            .catch(error => {
                console.error('La récupération des produits filtrés Mode a rencontré une erreur !', error);
            });
    }, []);

    const handleCategoryClick = (categorieId) => {
        navigate(`/categorie/${categorieId}`);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        axios.get(`${ConfigHost.API_URL}/produits/?search=${searchQuery}`)
            .then(response => {
                const produit = response.data[0]; // Suppose que le premier résultat est le bon
                if (produit) {
                    navigate(`/produit/${produit.id}`);
                } else {
                    alert('Produit non trouvé');
                }
            })
            .catch(error => {
                console.error('Erreur lors de la recherche du produit', error);
            });
    };

    const firstFiveProduits = filtrage.slice(0, 5);
    const firstFiveProduitsMode = filtrageMode.slice(0, 5);

    return (
        <div className='flex flex-col'>
            <div className="hero bg-base-200 min-h-72 lg:w-11/12 lg:min-h-96 lg:mt-10 lg:mx-auto md:rounded-xl relative">
                <div className="absolute top-0 right-1/4 m-4 hidden lg:block">
                    <i className="text-6xl opacity-10 bi bi-flower1"></i>
                </div>
                <div className="absolute top-0 left-64 m-4 transform rotate-45 hidden lg:block">
                    <i className="text-6xl opacity-10 bi-gift-fill"></i>
                </div>
                <div className="absolute bottom-0 left-24 m-4 transform rotate-45 hidden lg:block">
                    <i className="text-6xl opacity-10 bi-qr-code"></i>
                </div>
                <div className="absolute bottom-0 right-32 m-4 hidden lg:block">
                    <i className="text-6xl opacity-10 bi bi-snow"></i>
                </div>
                <div className="hero-content flex justify-between items-center p-4 w-full">
                    <div className="hidden lg:block">
                        <img
                            src={sac}
                            className="rounded-lg w-60 h-auto opacity-75"
                            alt="Produit"
                        />
                    </div>
                    <div className="w-full lg:max-w-2xl mx-4">
                        <h1 className="text-center text-3xl md:text-4xl font-bold">
                            Laissez-vous Émerveiller par <span className='text-green-600'>les Trésors</span> du Burkina Faso
                        </h1>
                        <p className="py-6 text-center text-gray-600">
                            Explorez notre plateforme dédiée à la richesse culturelle de l'Afrique en particulier l'AES. Nous vous proposons une gamme variée de produits locaux allant des vêtements traditionnels en Faso Dan Fani aux huiles précieuses issues du karité.
                        </p>
                        <div className="mt-4 bg-green-200 rounded-3xl p-2">
                            <form onSubmit={handleSearchSubmit} className="flex">
                                <input
                                    type="text"
                                    placeholder="Rechercher un produit"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className="input lg:h-15 w-full bg-green-200"
                                />
                                <button type="submit" className="text-4xl bg-green-200 text-gray-500 lg:h-15">
                                    <i className="bi bi-search"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="hidden lg:block">
                        <img
                            src={yennega}
                            className="rounded-lg w-60 h-auto opacity-75"
                            alt="Produit"
                        />
                    </div>
                </div>
            </div>
            <div className="border border-gray-300 rounded-xl grid grid-cols-3 md:grid-cols-4 lg:flex lg:mx-auto gap-8 my-8 p-4 md:p-4">
                <div className="dropdown">
                    <div tabIndex={0} role="button" className="btn btn-ghost btn-circle">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h7" />
                        </svg>
                    </div>
                    <ul
                        tabIndex={0}
                        className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
                        <li>
                            {categories.map(categorie => (
                                <button
                                    key={categorie.id}
                                    onClick={() => handleCategoryClick(categorie.id)}
                                    className='text-sm md:text-md text-gray-700'>
                                    {categorie.contenu}
                                </button>
                            ))}
                        </li>
                    </ul>
                </div>
                {categories.map(categorie => (
                    <button
                        key={categorie.id}
                        onClick={() => handleCategoryClick(categorie.id)}
                        className='hover:bg-green-100 hover:border-green-300 rounded-xl border border-gray-400 p-2 md:p-3 text-sm md:text-md text-gray-700'>
                        {categorie.contenu}
                    </button>
                ))}
            </div>
            <div className="w-full">
                <h1 className="text-gray-500 text-2xl font-bold md:text-3xl md:mx-10 md:my-5">Produits Populaires</h1>
                <div className="w-full rounded-lg flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3 mt-8 p-8">
                    {randomProduits.map(produit => (
                        <Link key={produit.id} to={`/produit/${produit.id}`}>
                            <div className="card bg-base-100 w-6/6 h-4/5 shadow-sm">
                                <figure className='bg-gray-100 w-full h-3/5 rounded-xl'>
                                    <img src={produit.photo} alt={produit.titre} />
                                </figure>
                                <div className="px-2">
                                    <h2 className="text-md card-title my-2">
                                        {produit.titre.length > 23 ? `${produit.titre.slice(0, 23)}...` : produit.titre}
                                    </h2>
                                    <p className='text-sm text-gray-600 my-2'>{produit.description.length > 66 ? `${produit.description.slice(0, 66)}...` : produit.description}

                                    </p>
                                    <p className={`${produit.promo ? 'line-through text-gray-500' : ''}`}>
                                        {produit.prix} FCFA
                                    </p>
                                    {produit.promo && (
                                        <p className="my-2 text-green-600 text-sm absolute top-2 right-2 bg-green-50 rounded-2xl p-1">
                                            - {Math.round(100 - (100 * produit.promo / produit.prix))}%
                                        </p>
                                    )}

                                    {produit.promo && (
                                        <p className="text-red-500">Promo : {produit.promo} FCFA</p>
                                    )}
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
            <div className="w-full flex justify-center">
                <div className="w-12/12 rounded-lg shadow-sm md:w-11/12">
                    <div className="rounded-t-lg border-b border-gray-300 bg-green-500 flex justify-start pl-10">
                        <h1 className="text-xl text-white py-4 font-bold">Categorie Artisanat</h1>
                    </div>
                    <div className="w-full rounded-lg flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 gap-3 p-8">
                        {firstFiveProduits.map(produit => (
                            <Link key={produit.id} to={`/produit/${produit.id}`}>
                                <div className="card bg-base-100 w-6/6 h-4/5 shadow-sm">
                                    <figure className='bg-gray-100 w-full h-3/5 rounded-xl'>
                                        <img src={produit.photo} alt={produit.titre} />
                                    </figure>
                                    <div className="px-2">
                                        <h2 className="text-md card-title my-2">
                                            {produit.titre.length > 23 ? `${produit.titre.slice(0, 23)}...` : produit.titre}
                                        </h2>
                                        <p className='text-sm text-gray-600 my-2'>{produit.description.length > 66 ? `${produit.description.slice(0, 66)}...` : produit.description}

                                        </p>
                                        <p className={`${produit.promo ? 'line-through text-gray-500' : ''}`}>
                                            {produit.prix} FCFA
                                        </p>
                                        {produit.promo && (
                                            <p className="my-2 text-green-600 text-sm absolute top-2 right-2 bg-green-50 rounded-2xl p-1">
                                                - {Math.round(100 - (100 * produit.promo / produit.prix))}%
                                            </p>
                                        )}

                                        {produit.promo && (
                                            <p className="text-red-500">Promo : {produit.promo} FCFA</p>
                                        )}
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>

            <div className="w-full flex justify-center">
                <div className="w-full rounded-lg my-6 shadow-sm md:w-11/12">
                    <div className="w-full rounded-t-lg border-b text-white border-gray-300  bg-green-500 flex justify-start pl-10">
                        <h1 className="text-xl py-4 font-bold">Categorie Mode</h1>
                    </div>
                    <div className="w-full rounded-lg flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 gap-3 p-8">
                        {firstFiveProduitsMode.map(produit => (
                            <Link key={produit.id} to={`/produit/${produit.id}`}>
                                <div className="card bg-base-100 w-6/6 h-4/5 shadow-sm">
                                    <figure className='bg-gray-100 w-full h-3/5 rounded-xl'>
                                        <img src={produit.photo} alt={produit.titre} />
                                    </figure>
                                    <div className="px-2">
                                        <h2 className="text-md card-title my-2">
                                            {produit.titre.length > 23 ? `${produit.titre.slice(0, 23)}...` : produit.titre}
                                        </h2>
                                        <p className='text-sm text-gray-600 my-2'>{produit.description.length > 66 ? `${produit.description.slice(0, 66)}...` : produit.description}

                                        </p>
                                        <p className={`${produit.promo ? 'line-through text-gray-500' : ''}`}>
                                            {produit.prix} FCFA
                                        </p>
                                        {produit.promo && (
                                            <p className="my-2 text-green-600 text-sm absolute top-2 right-2 bg-green-50 rounded-2xl p-1">
                                                - {Math.round(100 - (100 * produit.promo / produit.prix))}%
                                            </p>
                                        )}

                                        {produit.promo && (
                                            <p className="text-red-500">Promo : {produit.promo} FCFA</p>
                                        )}
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
            <div className="w-full mb-12 flex flex-col lg:w-12/12">
                <h1 className="text-gray-700 font-bold text-3xl py-4 text-center lg:text-5xl">Découvrez Notre Héritage</h1>
                <p className='text-gray-500 text-center my-2 text-md lg:text-xl lg:w-3/4 mx-auto'>
                    Explorez des produits endogènes authentiques sur notre plateforme dédiée. Plongez dans la richesse culturelle locale et soutenez les artisans et entreprises de votre région. Notre plateforme vous invite à découvrir, apprendre et participer activement à la préservation et à la valorisation de notre patrimoine culturel. Chaque achat et chaque formation contribuent à renforcer le tissu économique et social de votre communauté.
                </p>
            </div>
            <div className="w-full p-4 my-8 flex md:flex-row flex-col items-center gap-3">
                <div className="md:w-1/3 flex justify-center gap-6 p-4">
                    <div className="flex items-center justify-center -mr-3.5">
                        <i className="bi bi-globe text-4xl md:text-5xl text-green-400"></i>
                    </div>
                    <div className="ml-2">
                        <h2 className="font-semibold text-xl md:text-2xl text-gray-500">Authenticité Culturelle</h2>
                        <p className="text-gray-400">Un accès direct à des produits authentiques.</p>
                    </div>
                </div>
                <div className="md:w-1/3 flex justify-center gap-6 p-4">
                    <div className="flex items-center justify-center -mr-3.5">
                        <i className="bi bi-mouse text-4xl md:text-5xl text-green-400"></i>
                    </div>
                    <div className="ml-2">
                        <h2 className="font-semibold text-xl md:text-2xl text-gray-500">Simplicité d'Utilisation</h2>
                        <p className="text-gray-400">Notre plateforme est conçue pour une navigation fluide et intuitive</p>
                    </div>
                </div>
                <div className="md:w-1/3 flex justify-center gap-6 p-4">
                    <div className="flex items-center justify-center -mr-3.5">
                        <i className="bi bi-shield text-4xl md:text-5xl text-green-400"></i>
                    </div>
                    <div className="ml-2">
                        <h2 className="font-semibold text-xl md:text-2xl text-gray-500">Sécurité des Transactions</h2>
                        <p className="text-gray-400">EndoMarket priorise la sécurité de vos transactions</p>
                    </div>
                </div>
            </div>
            <div className="w-full md:flex md:flex-row flex flex-col items-center justify-center md:gap-8 p-4 my-2">
                <div className="flex items-center justify-center border-red-300 md:w-1/2">
                    <img src={image} alt="image" />
                </div>
                <div className="md:w-1/2 flex flex-col gap-10  pl-8 justify-center">
                    <div className="">
                        <h2 className="font-semibold text-3xl text-gray-600 md:w-2/3 pb-4">Nos Trésors Locaux en Un Clic</h2>
                        <p className="text-gray-400 md:w-3/4">Plongez dans un monde de produits authentiques et locaux. Avec un simple clic, explorez des articles qui célèbrent notre patrimoine culturel tout en bénéficiant d'un paiement rapide et sécurisé.</p>
                    </div>
                    <div className="">
                        <h2 className="font-semibold text-xl text-gray-600"><span className='bg-slate-400 rounded-full  px-3 mr-1'></span>Avant <span>En<span className='text-green-500'>do</span>Mar<span className='text-green-500'>k</span>et</span></h2>
                        <p className="text-gray-400 text-sm md:w-1/2">Trouver des articles qui reflètent la richesse de notre culture était un défi, souvent limité aux boutiques physiques ou aux recherches en ligne longues et incertaines.</p>
                    </div>
                    <div className="">
                        <h2 className="font-semibold text-xl text-gray-600"><span className='bg-green-500 rounded-full px-3 mr-1'></span>Avec <span>En<span className='text-green-500'>do</span>Mar<span className='text-green-500'>k</span>et</span></h2>
                        <p className="text-gray-400 text-sm md:w-1/2">Profitez d'une plateforme facile à utiliser pour explorer et acheter des produits authentiques. Notre interface conviviale vous permet de soutenir les artisans locaux tout en bénéficiant d'un paiement rapide et sécurisé.</p>
                    </div>
                    <div className="md:flex md:justify-start text-center">
                        <button className="btn bg-green-500 text-white">Voir plus</button>
                    </div>
                </div>
            </div>
            <div className="hero bg-base-200 my-10 lg:h-96">
                <div className="hero-content flex-col lg:flex-row-reverse">
                    <img
                        src={photo}
                        className="md:max-w-sm rounded-lg max-h-60 shadow-2xl" />
                    <div className='text-center mt-3 lg:w-4/6'>
                        <h1 className="text-4xl font-bold">Découvrez <span className='text-green-600'>l'Authenticité</span> du Burkina Faso.</h1>
                        <p className="py-6 text-gray-500">
                            Plongez dans l'univers fascinant du Burkina Faso avec notre sélection de produits endogènes. Nous vous offrons un accès direct à des créations artisanales uniques, des produits alimentaires savoureux et des objets de décoration authentiques. Chaque achat soutient les artisans locaux et contribue à préserver notre patrimoine culturel.
                        </p>
                        <button className="btn bg-green-500 text-white">Savoir plus</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layout;
