import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ConfigHost from '../../../../ConfigHost';

const Solde = () => {
    const [totalSolde, setTotalSolde] = useState(0);
    const [historique, setHistorique] = useState([]);

    // Fonction pour générer une date aléatoire dans le mois actuel
    const generateRandomDate = () => {
        const start = new Date(2024, 0, 1); // Date de début de l'année
        const end = new Date(); // Aujourd'hui
        const randomDate = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
        return randomDate.toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' });
    };

    // Fonction pour générer une heure aléatoire
    const generateRandomTime = () => {
        const hours = Math.floor(Math.random() * 24);
        const minutes = Math.floor(Math.random() * 60);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    };

    // Fonction pour générer un moyen de paiement aléatoire
    const generateRandomPaymentMethod = () => {
        const methods = ['Orange Money', 'Carte Bancaire'];
        return methods[Math.floor(Math.random() * methods.length)];
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userId = localStorage.getItem('user_id'); // Assuming user ID is stored in local storage
                const response = await axios.get(`${ConfigHost.API_URL}/commandes/?fournisseur_id=${userId}`);
                const commandes = response.data;

                // Calculer la somme des prix de tous les produits en s'assurant que commande.prix est bien un nombre
                const total = commandes.reduce((acc, commande) => {
                    const prix = Number(commande.prix); // Conversion en nombre
                    return acc + (isNaN(prix) ? 0 : prix); // Ajouter 0 si prix n'est pas un nombre valide
                }, 0);
                setTotalSolde(Math.floor(total)); // Conversion en entier

                // Ajouter des données par défaut pour remplir le tableau avec 10 lignes
                const defaultHistorique = Array.from({ length: 9}, (_, index) => ({
                    mois: generateRandomDate(),
                    heure: generateRandomTime(),
                    moyenPaiement: generateRandomPaymentMethod(),
                    total: 1000 * (index + 1)
                }));

                setHistorique(defaultHistorique);

            } catch (error) {
                console.error('Erreur lors de la récupération des commandes:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <h2 className='text-xl mb-7'>Accueil / <span className='text-gray-500'>Accéder au solde</span></h2>
            <div className="bg-white shadow-lg rounded-lg p-6 max-w-md mx-auto mt-10">
                <h1 className="text-3xl font-bold text-gray-800 mb-4 text-center">Solde Total</h1>
                <div className="bg-green-500 text-white text-4xl font-semibold p-4 rounded-lg text-center">
                    {totalSolde.toLocaleString('fr-FR')} FCFA
                </div>
                <p className="text-gray-600 mt-4 text-center">
                    Voici le montant total de vos ventes.
                </p>
            </div>
            <div className="w-12/12">
                {/* Tableau de l'historique des paiements mensuels */}
                <h2 className="text-xl font-semibold text-gray-800 mt-8 mb-4 text-center">Historique Mensuel des Paiements</h2>
                <table className="table-auto w-full">
                    <thead>
                        <tr className='bg-gray-100'>
                            <th className="px-4 py-2 text-left text-gray-600">Date</th>
                            <th className="px-4 py-2 text-left text-gray-600">Heure</th>
                            <th className="px-4 py-2 text-left text-gray-600">Moyen de Paiement</th>
                            <th className="px-4 py-2 text-right text-gray-600">Total FCFA</th>
                        </tr>
                    </thead>
                    <tbody>
                        {historique.map((item, index) => (
                            <tr key={index} className="border-t">
                                <td className="px-4 py-2 text-left">{item.mois}</td>
                                <td className="px-4 py-2 text-left">{item.heure}</td>
                                <td className="px-4 py-2 text-left">{item.moyenPaiement}</td>
                                <td className="px-4 py-2 text-right">{item.total.toLocaleString('fr-FR')} FCFA</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Solde;
