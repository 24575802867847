import React from 'react';
import Navbar from '../navbar/Navbar';
import Layout from './sous page/Layout';
import Footer from '../footer/Footer';

const Home = () => {
    return (
        <div>
            <Navbar/>
            <Layout/>
            <Footer/>
        </div>
    );
};

export default Home;