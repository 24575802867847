import React from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import ProfilPage from './sous page/ProfilPage';

const Profil = () => {
    return (
        <div>
            <Navbar/>
            <ProfilPage/>
            <Footer/>
        </div>
    );
};

export default Profil;