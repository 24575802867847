import React, { useState } from 'react';
import '../../../../css/ListeProduit.css';

const ListeProduit = ({ produits, handleEdit, handleDelete }) => {
    const [expandedProduct, setExpandedProduct] = useState(null);

    const toggleExpand = (productId) => {
        setExpandedProduct(expandedProduct === productId ? null : productId);
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return `${text.substring(0, maxLength)}...`;
        }
        return text;
    };

    return (
        <div>
            <h3 className="text-xl font-semibold mb-4">Liste des produits</h3>
            <table className="min-w-full divide-y divide-gray-200">
                <thead className=''>
                    <tr className='bg-green-200'>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Photo</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Titre</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Description</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Prix</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Promo</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Catégorie</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Actions</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {produits.map(produit => (
                        <tr key={produit.id} className="hover:bg-gray-100">
                            <td className="px-6 py-4 whitespace-nowrap">
                                <img
                                    src={produit.photo}
                                    alt={produit.titre}
                                    className="w-16 h-16 object-cover rounded-md"
                                />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">{produit.titre}</td>
                            <td className="px-6 py-4 whitespace-normal max-w-xs">
                                <div className={`description-container ${expandedProduct === produit.id ? 'expanded' : 'collapsed'}`}>
                                    {expandedProduct === produit.id ? produit.description : truncateText(produit.description, 50)}
                                </div>
                                <button
                                    onClick={() => toggleExpand(produit.id)}
                                    className="text-green-600 hover:text-green-900 ml-2"
                                >
                                    {expandedProduct === produit.id ? <i className="bi bi-caret-up-fill"></i> : <i className="bi bi-caret-down-fill"></i>}
                                </button>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">{produit.prix} FCFA</td>
                            <td className="px-6 py-4 whitespace-nowrap">{produit.promo ? `${produit.promo} FCFA` : 'Aucune'}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{produit.categorie}</td>
                            <td className="px-6 py-4 whitespace-nowrap flex items-center space-x-4">
                                <button
                                    onClick={() => handleEdit(produit.id)}
                                    className="text-green-600 hover:text-green-900"
                                >
                                    <i className="bi bi-pencil"></i>
                                </button>
                                <button
                                    onClick={() => handleDelete(produit.id)}
                                    className="text-red-600 hover:text-red-900"
                                >
                                    <i className="bi bi-trash"></i>
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ListeProduit;
