import React, { useState } from 'react';

const ProfilPage = () => {
    const [username] = useState(localStorage.getItem('username'));
    const [user_email] = useState(localStorage.getItem('user_email'));

    return (
        <div className="min-h-screen bg-gray-50">
            <div className="w-full border bg-green-200 border-gray-300 h-56 p-4 flex justify-center items-center">
                <span className="text-center text-4xl font-semibold text-gray-700 uppercase">Bienvenue :</span>
                <span className="ml-2 text-4xl font-bold text-gray-500">{username || 'Nom d\'utilisateur indisponible'}</span>
            </div>
            <div className="bg-white shadow-md rounded-lg w-11/12 md:w-2/3 lg:w-1/2 p-8">
                <div className="text-center mb-8">
                    <h1 className="text-3xl uppercase border-b">Mes informations</h1>
                    <div className='mt-2'>
                        <p className="w-1/5 rounded-full bg-orange-300 py-2">
                            <span className="text-8xl text-yellow-100">
                                {username ? username.charAt(0).toUpperCase() : 'N/A'}
                            </span>
                        </p>
                    </div>
                </div>

                {/* Profil details */}
                <div className="space-y-6">
                    <div className="flex items-center justify-between">
                        <span className="font-semibold text-gray-700">Nom d'utilisateur :</span>
                        <span className="text-gray-900">{username || 'Nom d\'utilisateur indisponible'}</span>
                    </div>
                    <div className="flex items-center justify-between">
                        <span className="font-semibold text-gray-700">Email :</span>
                        <span className="text-gray-900">{user_email || 'Email indisponible'}</span>
                    </div>
                </div>

                {/* Edit button */}
                <div className="mt-8 text-center">
                    <button className="bg-green-500 text-white px-6 py-2 rounded-lg shadow hover:bg-green-600 focus:outline-none">
                        Modifier le profil
                    </button>
                </div>

            </div>
        </div>
    );
};

export default ProfilPage;
