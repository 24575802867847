import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Connexion from '../../src/components/pages/sous page/seConnecter/Connexion';

const ProtectedRoute = ({ element }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userId = localStorage.getItem('user_id');
  const navigate = useNavigate();

  useEffect(() => {
    // Si l'utilisateur n'est pas connecté, ouvrir la modale
    if (!userId) {
      setIsModalOpen(true);
    }
  }, [userId]);

  const closeModal = () => {
    setIsModalOpen(false);
    // navigate('/');
  };

  // Si l'utilisateur est authentifié, afficher la page
  if (userId) {
    return element;
  }

  // Si l'utilisateur n'est pas authentifié, afficher la modale
  return (
    <>
      {isModalOpen && <Connexion isOpen={isModalOpen} onClose={closeModal} />}
    </>
  );
};

export default ProtectedRoute;
