import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ConfigHost from '../../../../ConfigHost';
import '../../../../css/ListeProduit.css';

const TableauDeBord = () => {
    const [stats, setStats] = useState({
        totalAchats: 0,
        totalCommentaires: 0,
        totalNotes: 0,
        totalProduits: 0,  // Ajout de l'état pour le total des produits
    });
    const [ventesDuJour, setVentesDuJour] = useState([]);
    const [meilleurProduit, setMeilleurProduit] = useState(null);
    const [meilleurProduitVentes, setMeilleurProduitVentes] = useState(0);
    const [meilleurClient, setMeilleurClient] = useState(null);
    const [meilleurClientCommandes, setMeilleurClientCommandes] = useState(0);
    const userId = localStorage.getItem('user_id');

    useEffect(() => {
        const fetchVentesDuJour = async () => {
            try {
                const today = new Date().toISOString().split('T')[0];
                const response = await axios.get(`${ConfigHost.API_URL}/commandes/?date_du_jour=${today}`);
                setVentesDuJour(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des ventes du jour :", error);
            }
        };

        const fetchMeilleurProduitEtClient = async () => {
            try {
                const response = await axios.get(`${ConfigHost.API_URL}/commandes/?fournisseur_id=${userId}`);
                const commandes = response.data;

                if (commandes.length > 0) {
                    // Compter le nombre de commandes par produit
                    const produitCount = commandes.reduce((acc, commande) => {
                        const produitId = commande.produit;
                        acc[produitId] = (acc[produitId] || 0) + 1;
                        return acc;
                    }, {});

                    // Identifier le meilleur produit
                    const meilleurProduitId = Object.keys(produitCount).reduce((a, b) => produitCount[a] > produitCount[b] ? a : b);
                    setMeilleurProduitVentes(produitCount[meilleurProduitId]);

                    const produitResponse = await axios.get(`${ConfigHost.API_URL}/produits/${meilleurProduitId}/`);
                    setMeilleurProduit(produitResponse.data);

                    // Compter le nombre de commandes par client
                    const clientCount = commandes.reduce((acc, commande) => {
                        const clientId = commande.client;
                        acc[clientId] = (acc[clientId] || 0) + 1;
                        return acc;
                    }, {});

                    // Identifier le meilleur client
                    const meilleurClientId = Object.keys(clientCount).reduce((a, b) => clientCount[a] > clientCount[b] ? a : b);
                    setMeilleurClientCommandes(clientCount[meilleurClientId]);

                    const clientResponse = await axios.get(`${ConfigHost.API_URL}/client/${meilleurClientId}/`);
                    setMeilleurClient(clientResponse.data);
                }
            } catch (error) {
                console.error("Erreur lors de la récupération du meilleur produit et du meilleur client :", error);
            }
        };

        const fetchTotalProduits = async () => {
            try {
                const produitResponse = await axios.get(`${ConfigHost.API_URL}/commandes/?fournisseur_id=${userId}`);
                setStats(prevStats => ({
                    ...prevStats,
                    totalProduits: produitResponse.data.length,  // Met à jour le total des produits
                }));
            } catch (error) {
                console.error("Erreur lors de la récupération du nombre total de produits :", error);
            }
        };

        const fetchTotalCommenatires = async () => {
            try {
                const commentaireResponse = await axios.get(`${ConfigHost.API_URL}/commentaires/?fournisseur_id=${userId}`);
                setStats(prevStats => ({
                    ...prevStats,
                    totalCommentaires: commentaireResponse.data.length,
                }));
            } catch (error) {
                console.error("Erreur lors de la récupération du nombre total de commentaires :", error);
            }
        };

        const fetchTotalNoteFournisseur = async () => {
            try {
                const noteFourResponse = await axios.get(`${ConfigHost.API_URL}/four_note/?fournisseur=${userId}`);

                // Calculer la somme des notes et la moyenne
                const totalNotes = noteFourResponse.data.reduce((acc, note) => acc + note.note_value, 0);
                const moyenne = totalNotes / noteFourResponse.data.length;

                setStats(prevStats => ({
                    ...prevStats,
                    totalNotes,
                    moyenne: isNaN(moyenne) ? 0 : moyenne,
                }));
            } catch (error) {
                console.error("Erreur lors de la récupération de la moyenne du fournisseur :", error);
            }
        };


        fetchVentesDuJour();
        fetchMeilleurProduitEtClient();
        fetchTotalProduits();
        fetchTotalCommenatires();
        fetchTotalNoteFournisseur();
    }, [userId]);

    return (
        <div>
            <div className="flex gap-14">
                <div className="stat border border-green-300 rounded-lg shadow-lg">
                    <h3 className='text-gray-400 text-xl'>Total des produits achetés</h3>
                    <div className="mt-2 flex justify-between">
                        <p className='text-3xl mt-4'>{stats.totalProduits}</p>
                        <i className="bg-green-500 rounded-xl py-2 px-3 text-white text-2xl mt-4 bi bi-bag-check-fill"></i>
                    </div>
                </div>
                <div className="stat border border-green-300 rounded-lg shadow-lg">
                    <h3 className='text-gray-400 text-xl'>Total des commentaires</h3>
                    <div className="mt-2 flex justify-between">
                        <p className='text-3xl mt-4'>{stats.totalCommentaires}</p>
                        <i className="bg-green-500 rounded-xl py-2 px-3 text-white text-2xl mt-4 bi bi-chat-fill"></i>
                    </div>
                </div>
                <div className="stat border border-green-300 rounded-lg shadow-lg">
                    <h3 className='text-gray-400 text-xl'>Ma note globale</h3>
                    <div className="mt-2 flex justify-between">
                        <p className='text-3xl mt-4'>{stats.moyenne}/5</p>
                        <i className="bg-green-500 rounded-xl py-2 px-3 text-white text-2xl mt-4 bi bi-card-checklist"></i>
                    </div>
                </div>
            </div>
            <div className="w-full mt-10">
                <div className="w-12/12 border border-gray-300 rounded-lg shadow-sm">
                    <h3 className="text-2xl my-4 mx-4 capitalize">Les ventes du jour</h3>
                    <table className="min-w-full bg-white divide-y divide-gray-200 shadow-md rounded-lg">
                        <thead className="bg-gray-200">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Produit</th>
                                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Unités</th>
                                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Montant</th>
                                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Date</th>
                                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Heure</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {ventesDuJour.length === 0 ? (
                                <tr>
                                    <td colSpan="5" className="px-6 py-4 text-center text-gray-500">
                                        Aucune vente pour aujourd'hui.
                                    </td>
                                </tr>
                            ) : (
                                ventesDuJour.map((vente) => (
                                    <tr key={vente.id}>
                                        <td className="px-6 py-4 whitespace-nowrap">{vente.produit_titre}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{vente.quantite}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{vente.prix} FCFA</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{new Date(vente.created_at).toLocaleDateString()}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{new Date(vente.created_at).toLocaleTimeString()}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="w-full mt-10">
                <div className="w-12/12 flex justify-between">
                    <div className="w-1/3 border border-gray-300 rounded-lg">
                        <h3 className='py-4 px-5 border-b'>Meilleur client</h3>
                        <div className="w-full px-8 py-3">
                            {meilleurClient ? (
                                <>
                                    <h3>{meilleurClient.last_name} {meilleurClient.first_name}</h3>
                                    <p className="text-gray-500 mt-2">{meilleurClientCommandes} commandes</p> {/* Affichage du nombre de commandes */}
                                </>
                            ) : (
                                <p>Aucun client trouvé.</p>
                            )}
                        </div>
                    </div>
                    <div className="w-1/2 border border-gray-300 rounded-lg">
                        <h3 className='py-4 px-5 border-b'>Meilleur Produit</h3>
                        <div className="w-full p-3 flex justify-around ">
                            {meilleurProduit ? (
                                <>
                                    <img src={meilleurProduit.photo} alt={meilleurProduit.titre} className="w-1/6 h-16 rounded-lg" />
                                    <div className="flex flex-col w-10/12 p-3">
                                        <div className="flex justify-between">
                                            <h3>{meilleurProduit.titre}</h3>
                                            <p className="mt-2">{meilleurProduitVentes} Ventes</p> {/* Affichage du nombre de ventes */}
                                        </div>
                                        <p className="text-gray-500 mt-2 truncate-description">
                                            {meilleurProduit.description}
                                        </p>
                                    </div>


                                </>
                            ) : (
                                <p>Aucun produit trouvé.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableauDeBord;
