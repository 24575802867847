import React from 'react';
import Navbar from '../navbar/Navbar';
import Formulaire from './sous page/inscription/Formulaire';
import Footer from '../footer/Footer';

const Inscription = () => {
    return (
        <div>
            <Navbar/>
            <Formulaire/>
            <Footer/>
        </div>
    );
};

export default Inscription;